import { ICampaign } from "src/reducers/campaigns/types";
import { ICampaignItem } from "src/reducers/campaign_items";

export const getCampaignItemField = (
  field: keyof ICampaignItem | "endAt" | "pixelRequired",
  { campaignItem, campaign }: { campaignItem: ICampaignItem; campaign?: ICampaign }
): any => {
  // these fields have been replaced by overridable fields
  switch (field) {
    case "startAt":
      return campaignItem.isV2
        ? campaignItem?.lifecycleSettings?.startAt?.value
        : campaignItem?.startAt;
    case "endAt":
      return campaignItem.isV2 ? campaignItem?.lifecycleSettings?.endAt?.value : campaign?.endsAt;
    case "frequencyConfigs":
      return campaignItem.isV2 ? campaignItem?.frequencyConfigs?.value : campaign?.frequencyConfigs;
    case "hardEndDate":
      return campaignItem.isV2 ? campaignItem?.hardEndDate?.value : campaign?.hardEndDate;
    case "pacing":
      return campaignItem.isV2 ? campaignItem?.pacing?.value : campaign?.pacing;
    case "requiresEndorsement":
      return campaignItem.isV2
        ? campaignItem?.requiresEndorsement?.value
        : campaign?.requiresEndorsement;
    case "pixelRequired":
      return campaignItem.isV2
        ? campaignItem?.lifecycleSettings?.pixelRequired?.value
        : campaign?.pixelRequired;
    case "targetingOptions":
      return campaignItem.isV2 ? campaignItem?.targetingOptions?.value : campaign?.targetingOptions;
    case "recentEpisodesOnly":
      return campaignItem.isV2
        ? campaignItem?.recentEpisodesOnly?.value
        : campaign?.recentEpisodesOnly;
    default:
      return campaignItem[field];
  }
};
