import dayjs, { Dayjs } from "dayjs";
import { createContext, useEffect, useState } from "react";
import { getScriptsForCampaign } from "src/action_managers/script";
import { CampaignItemStateDraft, CreditCardPaymentMethod } from "src/constants/campaigns";
import { useDispatchTS, useSelectorTS } from "src/hooks/redux-ts";
import { useForecastEndDates, useForecastImpressions } from "src/hooks/streamulator";
import { getAverageCPM, getBudget, getDailyImpressions } from "src/lib/campaigns";
import { CampaignPaymentType, ICampaign } from "src/reducers/campaigns/types";
import { ICampaignItem } from "src/reducers/campaign_items";
import { Script } from "src/reducers/scripts";
import { getAddScriptInitialState, ScriptForm } from "../add_script_modal/add_script_modal";
import {
  calcInitialValues,
  LocalScriptAssignments,
} from "../campaign_schedule_podcast/assign_script";
import {
  initializeCampaignDeadlines,
  initializeCampaignTimeline,
} from "./campaign_scheduler_utils";

interface IState {
  campaignUUID?: string;
  campaign?: ICampaign;
  campaignItems: ICampaignItem[];
  excludedCampaignItems: ICampaignItem[];

  budgets: { [campaignItemUUID: string]: number };
  setBudgets: (value: { [campaignItemUUID: string]: number }) => void;
  timeline: Dayjs[];
  setTimeline: (value: Dayjs[]) => void;
  deadlines: Record<string, Dayjs | undefined | null>;
  setDeadlines: (value: Record<string, Dayjs | undefined | null>) => void;
  minEndDatesState: ReturnType<typeof useForecastEndDates>;
  maxImpressionsState: ReturnType<typeof useForecastImpressions>;
  isSchedulerLoading: boolean;

  scripts: Script[];
  scriptState: ScriptForm[];
  setScriptState: (value: ScriptForm[]) => void;
  scriptAssignmentState: LocalScriptAssignments;
  setScriptAssignmentState: (value: LocalScriptAssignments) => void;

  paymentMethodType: CampaignPaymentType | undefined;
  setPaymentMethodType: (value: CampaignPaymentType | undefined) => void;
  paymentCardId: string | undefined;
  setPaymentCardId: (value: string | undefined) => void;
}

/**
 * CampaignSchedulerContext holds state for the entire campaign scheduler flow
 * This includes budgets, timelines and forecasted data
 */
export const CampaignSchedulerContext = createContext({} as IState);

export const CampaignSchedulerContextWrapper = ({
  children,
  campaignUUID,
}: {
  children: React.ReactNode;
  campaignUUID?: string;
}) => {
  const dispatch = useDispatchTS();
  const { user } = useSelectorTS((state) => state.user);

  // ALL STATE =====================================================================================
  const { campaigns, isLoading: isCampaignsLoading } = useSelectorTS((state) => state.campaigns);
  const campaign = campaignUUID ? campaigns[campaignUUID] : undefined;
  const {
    campaignItemByCampaignUUID,
    campaignItems: allCampaignItems,
    isLoading: isCampaignItemsLoading,
  } = useSelectorTS((state) => state.campaignItems);
  const campaignItemUUIDs =
    campaignUUID && campaignItemByCampaignUUID ? campaignItemByCampaignUUID[campaignUUID] : [];
  const campaignItems = campaignItemUUIDs
    ? campaignItemUUIDs.map((uuid) => allCampaignItems[uuid])
    : [];
  const draftItems = campaignItems.filter((item) => item.state === CampaignItemStateDraft);
  const publicShows = useSelectorTS((state) => state.publicShows);

  // check for exclusions
  const excludedCampaignItems =
    campaign &&
    campaignItems.filter((c) => {
      const show = publicShows[c.showUUID];
      const excludedCategories = show?.advertisementSettings?.excludedCategories;
      const excludedBrandInstanceUUIDs = show?.advertisementSettings?.excludedBrandInstanceUUIDs;
      const brand = campaign.brand;
      if (!brand) return false;
      return (
        excludedCategories?.includes(brand.iabCategory) ||
        excludedBrandInstanceUUIDs?.includes(brand.instanceUUID)
      );
    });

  // SCHEDULER STATE ===============================================================================
  const [budgets, setBudgets] = useState({});
  const [timeline, setTimeline] = useState<Dayjs[]>([]);
  const [deadlines, setDeadlines] = useState<Record<string, Dayjs | undefined | null>>({
    audioDue: undefined,
    responseDue: undefined,
  });
  const startDate = timeline?.[0]?.unix();
  const endDate = timeline?.[1]?.unix();

  // pacing forecasts
  const minEndDatesState = useForecastEndDates({
    user,
    campaign,
    draftItems,
    publicShows,
    budgets,
    startDate,
  });

  const maxImpressionsState = useForecastImpressions({
    user,
    campaign,
    draftItems,
    publicShows,
    startDate,
    endDate,
  });

  // initialize timeline
  useEffect(() => {
    if (!campaign) return;
    setTimeline(initializeCampaignTimeline(campaign));
  }, [campaign?.startsAt, campaign?.startsAtV2]);

  // update end dates from the reforecast when timeline changes for non-paced campaigns
  useEffect(() => {
    if (!campaign || campaign.pacing) return;
    const nonPacingLatestEndDate =
      Object.values(minEndDatesState.minEndDates)?.length > 0
        ? Object.values(minEndDatesState.minEndDates)?.reduce((accu: number, curr: any) => {
            if (!curr.streamulatorErrored) {
              accu = accu >= curr.estimatedEndTime ? accu : curr.estimatedEndTime;
            }
            return accu;
          }, startDate)
        : endDate;
    if (nonPacingLatestEndDate !== endDate) {
      setTimeline([timeline[0], dayjs.unix(nonPacingLatestEndDate)]);
    }
  }, [timeline, minEndDatesState]);

  // initialize deadlines
  useEffect(() => {
    if (!campaign) return;
    setDeadlines(initializeCampaignDeadlines(campaign));
  }, [campaign?.assignAudioDeadline, campaign?.responseDeadline]);

  // initialize budgets
  useEffect(() => {
    const isBudgetsEmpty = Object.keys(budgets).length === 0;
    const startDate = timeline?.[0]?.unix() ?? 0;
    const endDate = timeline?.[1]?.unix() ?? 0;
    const isPublicShowsLoaded =
      publicShows?.isLoading === false &&
      draftItems?.every(({ showUUID }) => publicShows?.[showUUID]?.uuid === showUUID);

    if (
      !!campaign &&
      Array.isArray(draftItems) &&
      draftItems.length > 0 &&
      !!publicShows &&
      isPublicShowsLoaded &&
      typeof startDate === "number" &&
      startDate !== 0 &&
      typeof endDate === "number" &&
      endDate !== 0 &&
      isBudgetsEmpty
    ) {
      const initialBudgets = draftItems.reduce(
        (acc, draftItem) => {
          const { uuid, showUUID, totalBudget } = draftItem;
          const publicShow = publicShows[showUUID];
          const cpm = getAverageCPM({ show: publicShow, campaign, campaignItem: draftItem });
          const dailyImpressions = getDailyImpressions(publicShow);
          acc[uuid] =
            typeof totalBudget === "number" && totalBudget > 0
              ? totalBudget
              : getBudget({ cpm, dailyImpressions, startDate, endDate });
          return acc;
        },
        {} as { [campaignItemUUID: string]: number }
      );
      setBudgets(initialBudgets);
    }
  }, [campaign, draftItems, publicShows, timeline, budgets]);

  // SCRIPTS STATE =================================================================================
  // TODO: clean up scripts code - this is wildin
  const {
    scriptsByCampaignUUID,
    scriptsByUUID,
    isLoading: isScriptsLoading,
  } = useSelectorTS((state) => state?.scripts);
  const scripts = (campaign && scriptsByCampaignUUID[campaign.uuid]) || [];
  const [scriptState, setScriptState] = useState<ScriptForm[]>([]);
  const [scriptAssignmentState, setScriptAssignmentState] = useState<LocalScriptAssignments>(null);

  useEffect(() => {
    const fetchScripts = async (campaignUUID: string) =>
      await dispatch(getScriptsForCampaign(campaignUUID));
    if (campaignUUID) fetchScripts(campaignUUID);
  }, [campaignUUID]);

  useEffect(() => {
    if (campaign && scripts) {
      setScriptState(
        getAddScriptInitialState({
          campaign,
          scripts,
          type: "editAll",
          initialize: "empty",
        })
      );
    }
  }, [campaign, scripts?.length]);

  const initialAssignedState = calcInitialValues({
    items: draftItems,
    publicShows,
    scriptsByUUID,
  });

  // Initially sync assigned script local state
  useEffect(() => {
    if (scriptAssignmentState === null && initialAssignedState !== null) {
      // Only fires once to remove initial state of null
      setScriptAssignmentState(initialAssignedState);
    }
  }, [initialAssignedState, scriptAssignmentState]);

  useEffect(() => {
    if (!isScriptsLoading) setScriptAssignmentState(initialAssignedState);
  }, [isScriptsLoading]);

  // PAYMENTS STATE ================================================================================
  const [paymentMethodType, setPaymentMethodType] = useState<CampaignPaymentType | undefined>(
    user.invoicingPaymentEnabled ? undefined : CreditCardPaymentMethod
  );
  useEffect(() => {
    if (campaign?.paymentType) setPaymentMethodType(campaign.paymentType);
  }, [campaign?.paymentType]);

  const [paymentCardId, setPaymentCardId] = useState<string | undefined>(undefined);

  return (
    <CampaignSchedulerContext.Provider
      value={{
        // ALL STATE
        campaignUUID,
        campaign,
        campaignItems,
        excludedCampaignItems,

        // SCHEDULER STATE
        budgets,
        setBudgets,
        timeline,
        setTimeline,
        deadlines,
        setDeadlines,
        minEndDatesState,
        maxImpressionsState,
        isSchedulerLoading: isCampaignsLoading || isCampaignItemsLoading,

        // SCRIPTS STATE
        scripts,
        scriptState,
        setScriptState,
        scriptAssignmentState,
        setScriptAssignmentState,

        // PAYMENTS STATE
        paymentMethodType,
        setPaymentMethodType,
        paymentCardId,
        setPaymentCardId,
      }}>
      {children}
    </CampaignSchedulerContext.Provider>
  );
};
