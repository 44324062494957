import dayjs from "dayjs";
import numeral from "numeral";
import Papa from "papaparse";
import { useEffect, useState } from "react";
import { showInfo } from "src/actions/app";
import { InsertionStatsActionManager } from "src/action_managers/stats";
import { downloadFile } from "src/components/lib/download_file";
import { svgIcon as Icon } from "src/components/lib/icon";
import { useReduxDispatch, useSelectorTS } from "src/hooks/redux-ts";
import { getCampaignField } from "src/lib/campaign";
import { getCampaignCPMsForShow } from "src/lib/campaigns";
import { getDatesInBetween } from "src/lib/date";
import { BudgetsReduxState } from "src/reducers/budgets";
import { ICampaign } from "src/reducers/campaigns/types";
import { ICampaignItem } from "src/reducers/campaign_items";
import { PublicShowsReduxState } from "src/reducers/public_show";
import { IShowMap } from "src/reducers/shows/showReducer";
import {
  DataIsLoadingMessage,
  StatsRequestFilter,
  yAxisLabelFormatter,
} from "../../analytics/analyticsUtility";
import RCColumn from "../../analytics/RedCircle_graphs/Column";
import TextSwitch from "../../analytics/TextSwitch";
import { getCampaignEstimatedDates } from "./campaign_page_utils";

const mapToFriendlyIntervalName: Record<"1M" | "1w" | "1d", "month" | "week" | "day"> = {
  "1M": "month",
  "1w": "week",
  "1d": "day",
};
interface ICampaignDashboardGraph {
  campaign: ICampaign;
  campaignItems: ICampaignItem[];
  shows: IShowMap | PublicShowsReduxState;
  budgets: BudgetsReduxState;
}

const CampaignDashboardGraph = ({
  campaign,
  campaignItems,
  shows,
  budgets,
}: ICampaignDashboardGraph) => {
  const { user, isLoading: isUserLoading } = useSelectorTS((state) => state.user);

  const [timeInterval, setTimeInterval] = useState<"1M" | "1w" | "1d">("1w");

  const cacheID = `campaign_impression_spent_${campaign?.uuid}_${timeInterval}`;

  const rawData = useSelectorTS((state) => state.stats?.stats?.[cacheID]);
  const isRawDataLoading = useSelectorTS(
    (state) => state.stats?.isLoading && state.stats.loadingUUIDs.includes(cacheID)
  );

  const campaignDates = getCampaignEstimatedDates(shows, campaign, campaignItems, budgets);
  const campaignStartDate = campaignDates?.start ?? getCampaignField("startsAt", { campaign });
  const campaignEndDate = campaignDates?.end ?? getCampaignField("endsAt", { campaign });

  const now = dayjs().unix();
  const campaignHasStarted =
    typeof campaignDates?.start === "number" && campaignStartDate
      ? now >= campaignStartDate
      : false;

  const dispatch = useReduxDispatch();

  const showCPMMap = campaignItems?.reduce(
    (accu, curr) => {
      if (curr && campaign && shows?.[curr?.showUUID]) {
        accu[curr?.showUUID] = getCampaignCPMsForShow({
          campaign,
          campaignItem: curr,
          show: shows?.[curr?.showUUID],
        });
      }

      return accu;
    },
    {} as Record<string, Partial<Record<string, number>>>
  );

  const transformCampaignImpression = (
    data: { date: number; count: number; pathValues: string[] }[]
  ) => {
    if (!data) return [];

    const formattedInterval = mapToFriendlyIntervalName[timeInterval];

    const initMap = createInitDateMap({
      startDate: campaignStartDate,
      endDate: campaignEndDate,
      interval: formattedInterval,
    });

    const mapper = data.reduce((accu, { date, count, pathValues }) => {
      const [_, showUUID] = pathValues;
      const position = pathValues[2].toLowerCase()?.replace("roll", "Roll");
      date = dayjs.unix(date).startOf(formattedInterval).unix();

      if (!accu?.[date]) {
        accu[date] = {
          impressions: 0,
          spent: 0,
        };
      }

      accu[date].spent += (count / 1000) * ((showCPMMap?.[showUUID]?.[position] ?? 0) / 100);
      accu[date].impressions += count;

      return accu;
    }, initMap);

    return Object.entries(mapper)?.map(([date, { impressions, spent }]) => {
      return {
        key: date,
        date: Number(date),
        impressions,
        spent,
      };
    });
  };

  /**
   * Grabbing Campaign Impressions
   */
  useEffect(() => {
    if (
      !isUserLoading &&
      !!user &&
      !!cacheID &&
      !!campaignDates &&
      typeof campaignStartDate === "number" &&
      typeof campaignEndDate === "number" &&
      campaignHasStarted &&
      !Array.isArray(rawData) &&
      !isRawDataLoading
    ) {
      const filters: StatsRequestFilter = {
        isUnique: true,
        timeRange: "allTime",
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        campaignUUID: campaign.uuid,
        interval: timeInterval,
        bucketTerms: "insertion.showUUID,insertion.markerPosition",
      };

      dispatch(
        new InsertionStatsActionManager({
          filters,
          user,
          requestID: cacheID,
        }).run()
      );
    }
  }, [
    user,
    isUserLoading,
    isRawDataLoading,
    timeInterval,
    cacheID,
    rawData?.length,
    campaignDates,
    campaignStartDate,
    campaignEndDate,
    campaignHasStarted,
  ]);

  const graphData = transformCampaignImpression(rawData);

  const totalSpent = graphData?.reduce((accu, { spent = 0 }) => {
    return accu + spent;
  }, 0);

  // Handlers

  const onSpendDataDownload = () => {
    if (!isRawDataLoading || graphData.length !== 0) {
      const csv = Papa.unparse(
        graphData.map((point) => {
          return {
            date: dateFormatter.format(point.date * 1000),
            Spent: numeral(point.spent).format("0.00"),
            Impressions: point.impressions,
          };
        })
      );

      downloadFile(
        `${campaign?.name}_${mapToFriendlyIntervalName[timeInterval]}_${dateFormatter.format(
          Date.now()
        )}.csv`,
        csv
      );
    } else {
      dispatch(showInfo(DataIsLoadingMessage, 3000));
    }
    return null;
  };

  return (
    <div className="campaign-cart p-hm p-vs m-bm">
      <div className="flex-row-container justify-start align-center">
        <span className="bold color-gray uppercase fs-11 lh-xs ls-1 m-bs">Spend over time</span>
        <TextSwitch
          className="m-la m-rs"
          options={[
            { key: "1M", value: "Month" },
            { key: "1w", value: "Week" },
            { key: "1d", value: "Day" },
          ]}
          defaultSelected={timeInterval}
          onSelect={(option) => setTimeInterval(option.key as typeof timeInterval)}
        />
        <div
          className={`${
            !campaignHasStarted ? "pointer-events-none" : ""
          } flex-row-container align-center pointer`}
          onClick={onSpendDataDownload}>
          <Icon
            name="reportDownload"
            height={16}
            width={16}
            style={{
              bottom: ".125em",
              position: "relative",
              cursor: "pointer",
              fill: "#EA404D",
            }}
          />
          <span className="capitalize c-red m-lxxxs fs-13 lh-m ls-.6">Spend data</span>
        </div>
      </div>
      <div className="m-bs">
        <span className="fs-20 lh-28 bold">${numeral(totalSpent).format("0,0.00")}</span>
        <span className=" m-lxxs capitalize fs-8 lh-12 ls-.6 "> total spent</span>
      </div>
      <div>
        <span></span>
      </div>
      <RCColumn<(typeof graphData)[number]>
        noDataMessage={"Campaign has not started"}
        loading={isRawDataLoading}
        data={campaignHasStarted ? graphData : []}
        xField="date"
        yField="spent"
        color={"#577D9E"}
        tooltip={{
          customContent: customTooltip,
        }}
        meta={{
          date: {
            formatter: (date) => {
              return dateFormatter.format(date * 1000);
            },
          },
          spent: {
            formatter: (spent) => {
              return `$${yAxisLabelFormatter(spent)}`;
            },
          },
        }}
      />
    </div>
  );
};

// Helper funcs
const dateFormatter = new Intl.DateTimeFormat(undefined, {
  hour12: true,
  year: "2-digit",
  month: "2-digit",
  day: "2-digit",
});

const customTooltip = (date: string, record: any) => {
  const impressions = record?.[0]?.data?.impressions ?? 0;
  const spent = record?.[0]?.data?.spent ?? 0;

  return `<div class="p-axs flex-column-container align-start">
        <span class="analyticsPage-GraphTooltip--title">${date}</span>
          <div class="analyticsPage-GraphTooltip--text">
            <strong>${numeral(impressions).format("0,0")}</strong> Downloads
          </div>
          <div class="analyticsPage-GraphTooltip--text">  
            <strong>$${numeral(spent).format("0,0.00")}</strong> Spent
          </div>
        </div>`;
};

/**
 *
 * @param startDate start date unix time stamp
 * @param endDate end date unix time stamp
 * @param interval interval to evenly split between start and end date
 */
const createInitDateMap: (props: {
  startDate?: number;
  endDate?: number;
  interval: "month" | "week" | "day";
}) => Record<string, { impressions: number; spent: number }> = ({
  startDate,
  endDate,
  interval,
}) => {
  if (typeof startDate === "number" && typeof endDate === "number" && endDate > startDate) {
    const dates = getDatesInBetween({
      startDate,
      endDate,
      interval,
    });

    const result: Record<string, { impressions: number; spent: number }> = {};

    dates.reduce((accu, curr) => {
      accu[curr] = {
        impressions: 0,
        spent: 0,
      };
      return accu;
    }, result);

    return result;
  }

  return {};
};

export default CampaignDashboardGraph;
