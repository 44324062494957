import { FilterOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Input, Row, Switch, theme } from "antd";
import { SortOrder } from "antd/es/table/interface";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { showSuccess, showWarning } from "src/actions/app";
import {
  assignItemToTag,
  createCampaignTag,
  deleteCampaignTag,
  getCampaign,
  getCampaignsForUser,
  getCampaignTags,
  updateCampaignMeta,
  updateCampaignTag,
} from "src/action_managers/campaigns";
import RCButton from "src/components/lib/button";
import EmptyStateBlock from "src/components/lib/empty_state_block/EmptyStateBlock";
import Loading from "src/components/lib/loading";
import Page from "src/components/lib/page";
import { CAMPAIGN_TAG_TYPE__CAMPAIGN } from "src/constants/campaigns";
import { permissionPresets } from "src/constants/permission_roles";
import { useGetCampaigns, useGetCampaignTags } from "src/hooks/campaigns";
import { useDebounce } from "src/hooks/lib";
import { useGetMyPermissions } from "src/hooks/org";
import { useReduxDispatch, useSelectorTS } from "src/hooks/redux-ts";
import { useLocalStorage } from "src/hooks/useLocalStorage";
import { isUserPresetAllowed } from "src/lib/permission_roles";
import { ICampaign, ICampaignTag } from "src/reducers/campaigns/types";
import {
  ArchiveCampaignModal,
  DeleteCampaignTagModal,
  EditCampaignTagModal,
  MoveToFolderModal,
  NewCampaignTagModal,
} from "./campaign_list_page_modals";
import {
  filterByCollapsedFolders,
  filterBySearch,
  joinTagsWithCampaigns,
  sortCampaigns,
  sortTags,
} from "./campaign_list_page_utils";
import CampaignListTable from "./campaign_list_table";

const DEFAULT_SORT_ORDER: { columnKey: string; order: SortOrder } = {
  columnKey: "state",
  order: "ascend",
};

const { useToken } = theme;

export default function CampaignListPage() {
  const dispatch = useReduxDispatch();
  const { user } = useSelectorTS((state) => state.user);
  const { orgPermission } = useGetMyPermissions();

  // UI STATE
  const [st, setSearchText] = useState("");
  const searchText = useDebounce(st, 300);

  const [showFolderModal, setFolderModal] = useState(false);
  const [showEditModal, setEditModal] = useState<ICampaignTag | undefined>();
  const [showDeleteModal, setDeleteModal] = useState<ICampaignTag | undefined>();
  const [showMoveModalUUID, setMoveModalUUID] = useState<string | undefined>();
  const [archiveCampaign, setArchiveCampaign] = useState<ICampaign | undefined>();

  const [tableSort, setTableSort] = useLocalStorage("campaign_table_sort", DEFAULT_SORT_ORDER);
  const [collapsedFolders, setCollapsedFolders] = useLocalStorage("campaign_collapsed_folders", {});
  const [filters, setFilters] = useLocalStorage("campaign_list_filters", {
    archive: false,
  });

  const [open, setOpen] = useState(false);
  const { token } = useToken();

  // CAMPAIGNS
  const {
    campaignsForUserIsLoading: isLoading,
    campaignsForUser,
    campaigns: allCampaigns,
  } = useGetCampaigns();
  const campaigns = useMemo(
    () => campaignsForUser?.map((uuid: string) => allCampaigns?.[uuid]),
    [campaignsForUser, allCampaigns]
  );

  const campaignsSorted = useMemo(
    () => sortCampaigns(campaigns, tableSort),
    [campaigns, tableSort]
  );

  const parentFolderToChildCampaignMap = useMemo(
    () =>
      campaigns.reduce(
        (accu, curr) => {
          if (
            Array.isArray(curr?.parentCampaignTagUUIDs) &&
            curr?.parentCampaignTagUUIDs?.length > 0
          ) {
            const campaign = curr as ICampaign;
            const parentUUID = campaign?.parentCampaignTagUUIDs?.[0] ?? "";

            if (!Array.isArray(accu[parentUUID])) {
              accu[parentUUID] = [];
            }
            accu[parentUUID].push(campaign);
          }

          return accu;
        },
        {} as { [parentFolderUUID: string]: ICampaign[] }
      ),
    [campaigns]
  );

  // FOLDERS
  const campaignTags = useGetCampaignTags();
  const tagsSorted = useMemo(() => sortTags(campaignTags, tableSort), [campaignTags, tableSort]);

  // join campaigns and folders (float folders to top with children)
  const filteredDataBySearch = useMemo(() => {
    const filterDataByFilters = campaignsSorted?.filter((campaign) =>
      filters.archive ? true : !campaign.archived
    );
    const joinedData = joinTagsWithCampaigns(filterDataByFilters, tagsSorted);
    return filterBySearch(joinedData, searchText);
  }, [campaignsSorted, tagsSorted, searchText, filters]);

  const data = useMemo(
    () => filterByCollapsedFolders(filteredDataBySearch, collapsedFolders),
    [filteredDataBySearch, collapsedFolders]
  );

  const hasCampaigns = campaigns && campaigns.length > 0;

  // Going to only give campaign admins the ability to add new Folders, since no explicit permission exists
  const canAddNewFolder = isUserPresetAllowed({
    userPreset: orgPermission.preset,
    limitPreset: permissionPresets.campaignEditor,
  });

  // Going to only give campaign admins the ability to add new Campaigns, since no explicit permission exists
  const canAddNewCampaign = isUserPresetAllowed({
    userPreset: orgPermission.preset,
    limitPreset: permissionPresets.campaignEditor,
  });

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    return (
      typeof event?.target?.value === "string" && setSearchText(event?.target?.value.toLowerCase())
    );
  };

  const handleCreateTag = (value: string) => {
    dispatch(createCampaignTag(user.uuid, value)).then((res) => {
      if (res.status !== 200) {
        dispatch(showWarning("Something went wrong! Please try again later."));
      }
      setFolderModal(false);
      dispatch(getCampaignTags(user.uuid));
    });
  };

  const handleUpdateTag = (title: string, tag: ICampaignTag) => {
    dispatch(updateCampaignTag({ ...tag, title })).then((res) => {
      if (res.status !== 200) {
        dispatch(showWarning("Something went wrong! Please try again later."));
      }
      setEditModal(undefined);
      dispatch(getCampaignTags(user.uuid));
    });
  };

  const handleDeleteTag = (tag: ICampaignTag) => {
    dispatch(deleteCampaignTag(tag.uuid)).then((res) => {
      if (res.status !== 204) {
        dispatch(showWarning("Something went wrong! Please try again later."));
      }
      setDeleteModal(undefined);
      dispatch(getCampaignTags(user.uuid));
      dispatch(getCampaignsForUser(user.uuid));
    });
  };

  const handleToggleFolder = (uuid: string) => {
    const newCollapsedFolders = { ...collapsedFolders };
    if (newCollapsedFolders[uuid]) {
      delete newCollapsedFolders[uuid];
    } else {
      newCollapsedFolders[uuid] = true;
    }
    setCollapsedFolders(newCollapsedFolders);
  };

  const handleAssign = (campaign: ICampaign, tag?: ICampaignTag) => {
    const parentUUID = campaign?.parentCampaignTagUUIDs?.[0];
    dispatch(
      assignItemToTag({
        childUUID: campaign.uuid,
        newParentUUID: tag ? tag.uuid : undefined,
        existingParentUUID: parentUUID,
        childType: CAMPAIGN_TAG_TYPE__CAMPAIGN,
      })
    )
      .then((res) => {
        dispatch(getCampaign(campaign.uuid));
        if (res.status === 200) {
          const successMessage = tag
            ? `Moved ${campaign.name} to ${tag?.title}!`
            : `Moved ${campaign.name}!`;
          dispatch(showSuccess(successMessage));
        }
      })
      .catch((e) => {
        dispatch(showWarning("Something went wrong! Please try again later."));
      });
  };

  const handleSortColumn = ({ columnKey, order }: { columnKey: string; order: SortOrder }) => {
    setTableSort(order ? { columnKey, order } : DEFAULT_SORT_ORDER);
  };

  const handleAssignFromModal = (tagUUID: string | null) => {
    if (showMoveModalUUID && allCampaigns[showMoveModalUUID]) {
      handleAssign(
        allCampaigns[showMoveModalUUID],
        tagUUID !== null ? campaignTags.find((t) => t.uuid === tagUUID) : undefined
      );
    }
  };

  const handleArchiveCampaign = (campaign: ICampaign) => {
    const successMessage = campaign?.archived
      ? `"${campaign.name}" is un-archived successfully`
      : `"${campaign.name}" is archived successfully`;

    dispatch(
      updateCampaignMeta(campaign.uuid, { archived: !campaign?.archived }, { successMessage })
    ).then(() => setArchiveCampaign(undefined));
  };

  const handleArchiveModalToggle = (campaign?: ICampaign) => {
    campaign?.archived ? handleArchiveCampaign(campaign) : setArchiveCampaign(campaign);
  };

  const canCreateCampaigns = isUserPresetAllowed({
    userPreset: orgPermission.preset,
    limitPreset: permissionPresets.campaignEditor,
  });

  const emptyStateMessage = canCreateCampaigns
    ? "Create a campaign and invite relevant podcasts to run your promotion on their shows."
    : "You don't have access to any campaigns and don't have permission to create new ones. Contact your organization's administrator to adjust your permissions.";

  const isFilteredHighlighted = Object.values(filters)?.some((flag) => flag);
  const IconColor = isFilteredHighlighted ? token.colorPrimary : "#808080";

  return (
    <Page className="campaign-list-page" pageTitle="Campaigns">
      <Page.Header className="flex-row-container align-end justify-space-between">
        <Page.Title>Campaigns</Page.Title>
        <span className="flex-row-container justify-end flex-wrap">
          {hasCampaigns && canAddNewFolder && (
            <RCButton type="link" onClick={() => setFolderModal(true)} size="small">
              New Folder
            </RCButton>
          )}
          {hasCampaigns && canAddNewCampaign && (
            <Link to="/campaigns/new">
              <RCButton type="link" size="small" className="m-lxs">
                New Campaign
              </RCButton>
            </Link>
          )}
        </span>
      </Page.Header>
      <Page.Divider />

      {isLoading && <Loading />}

      {/* EMPTY STATE */}
      {!isLoading && !hasCampaigns && (
        <Page.Section className="text-center">
          <EmptyStateBlock className="m-bm">{emptyStateMessage}</EmptyStateBlock>
          {canAddNewCampaign && (
            <Row>
              <Col xs={{ span: 24 }} md={{ span: 8, offset: 8 }}>
                <Link to="/campaigns/new">
                  <RCButton type="primary" size="large" className="width-100">
                    New Campaign
                  </RCButton>
                </Link>
              </Col>
            </Row>
          )}
        </Page.Section>
      )}

      {/* TABLE */}
      {!isLoading && hasCampaigns && (
        <Page.Section>
          <div className="flex-row-container align-center m-bxs">
            <Input.Search
              id="campaignList"
              className="width-100"
              style={{ width: "100%" }}
              placeholder="Search Campaigns by name or reference number"
              onChange={handleSearchChange}
              allowClear={true}
            />
            <Dropdown
              trigger={["click"]}
              open={open}
              onOpenChange={(flag) => setOpen(flag)}
              menu={{
                items: [
                  {
                    key: "1",
                    label: (
                      <div className="flex-row-container align-center">
                        <p className="m-b0 m-rxxs">Show Archived Campaigns</p>
                        <Switch
                          checked={filters.archive}
                          onChange={(flag) => {
                            setFilters((prev) => {
                              return { ...prev, archive: !prev.archive };
                            });
                          }}
                        />
                      </div>
                    ),
                  },
                ],
              }}>
              <Button className="m-lxxs p-hxxxs" type="default">
                <FilterOutlined style={{ color: IconColor }} />
              </Button>
            </Dropdown>
          </div>
          <CampaignListTable
            data={data}
            sort={tableSort}
            totalCampaigns={filteredDataBySearch?.length}
            onAssign={handleAssign}
            folderState={collapsedFolders}
            onToggleFolder={handleToggleFolder}
            onEditFolder={(tag: ICampaignTag) => setEditModal(tag)}
            onDeleteFolder={(tag: ICampaignTag) => setDeleteModal(tag)}
            onShowMoveModal={setMoveModalUUID}
            onSortColumn={handleSortColumn}
            onArchiveToggle={handleArchiveModalToggle}
            parentFolderToChildCampaignMap={parentFolderToChildCampaignMap}
          />
        </Page.Section>
      )}

      <NewCampaignTagModal
        open={showFolderModal}
        onSubmit={handleCreateTag}
        onClose={() => setFolderModal(false)}
      />
      <EditCampaignTagModal
        open={!!showEditModal}
        tag={showEditModal}
        onClose={() => setEditModal(undefined)}
        onSubmit={handleUpdateTag}
      />
      <DeleteCampaignTagModal
        open={!!showDeleteModal}
        tag={showDeleteModal}
        onClose={() => setDeleteModal(undefined)}
        onSubmit={handleDeleteTag}
      />
      <MoveToFolderModal
        open={!!showMoveModalUUID}
        item={showMoveModalUUID && (allCampaigns[showMoveModalUUID] as any)}
        tags={campaignTags}
        onSubmit={handleAssignFromModal}
        onClose={() => setMoveModalUUID(undefined)}
      />
      <ArchiveCampaignModal
        open={!!archiveCampaign}
        campaign={archiveCampaign}
        onSubmit={handleArchiveCampaign}
        onClose={() => setArchiveCampaign(undefined)}
      />
    </Page>
  );
}
